import camelCase from 'lodash/camelCase'

const requireModule = require.context('.', false, /\.js$/)

const modules = {}

requireModule.keys().forEach(fileName => {
  // Escludi in file index.js dalla creazione dell'oggetto dei moduli
	if (fileName === './index.js') return

	// Elimina l'estensione compreso il punto, e modifica il nome in camelCase
	const moduleName = camelCase(
		fileName.replace(/(\.\/|\.js)/g, '')
	)

	// Crea dinamicamente un oggetto con tutti i nomi dei moduli
	modules[moduleName] = {
		// Imposta come default il namespace per ogni modulo
		namespaced: true,
		...requireModule(fileName).default
	}
})
export default modules