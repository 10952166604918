<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 copyright">
          &copy; Copyright {{ year }} <a href="https//samag.tech" target="_blank" class="copyright-link">Samag Tech s.r.l.</a>
        </div>
      </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const year = computed(() => {
    const date =  new Date();

    return new Intl.DateTimeFormat('it-IT', {
        year: 'numeric'
    }).format(new Date(date))
})
</script>

<style>
.copyright {
    color: var(--bs-gray-600);
    font-size: 10px;
}

.copyright-link {
    color: #e77204;
    text-decoration: none;
}
</style>