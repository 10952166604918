import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faMagnifyingGlass,
  faBell,
  faToolbox,
  faInfoCircle,
  faHome
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faMagnifyingGlass,
  faBell,
  faToolbox,
  faInfoCircle,
  faHome
)