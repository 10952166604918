import { Auth } from "aws-amplify";

const auth = {};

auth.state = {
  authorized: false,
  user: null,
  loginError: '',
  mainCompanyLogo: {
    'samag.tech': 'sth.png',
    'cbslavoro.it': 'cbs.svg',
    'idiasrl.com': 'idia.png',
    'samagholding.com': "sgh.svg"
  }
}

auth.mutations = {
  // Imposta l'oggetto con i dati dell'utete e verifica se la sua email è stat
  // autorizzata.
  setUser(state, user) {
    state.authorized = !!user && user.attributes ? true : false;
    state.user = user;
  },
  setAuthorized(state, payload) {
    state.authorized = payload;
  }
}

auth.actions = {
  async fetchUser({commit, dispatch}){
    try{
      // Attributi dell'utente loggato
      const user = await Auth.currentAuthenticatedUser()

      // Secondi alla scadenza del token
      const expires = user.getSignInUserSession().getIdToken().payload.exp - Math.floor(new Date().getTime() / 1000)

      // Aggionrmaneto della sessione alla scadenza dell'accessToken
      setTimeout(async () => {
        await dispatch('refreshSession');
      }, expires * 1000)

      // Aggiornamento dei dati dell'utente
      commit('setUser', user)

    }catch(err){
      commit('setUser', null)
    }
  },

  /**
   * Aggiorna la sessione dell'utente
   * @param {*} param0
   */
  async refreshSession({dispatch}) {
    // Aggiornamento automatico della sessione dell'utente
    await Auth.currentSession();
    await dispatch('fetchUser')
  },

  async logout({commit}){
    await Auth.signOut()
    commit('user', null)
  },
}

auth.getters = {
  getUser(state) {
    return state.user
  },
  isAuthorized(state) {
    return state.authorized
  },
  getHomeLogo: (state) => (company) => {
    return state.mainCompanyLogo[company] ?? 'rstr.png'
  },
  getUserDomain(state) {
    const regex = /[^@]+$/;
    return regex.exec(state.user.attributes.email)[0]
  }
}

export default auth;