<template>
  <component :is="route.meta.layout || 'div'">
    <router-view />
  </component>
</template>

<script setup>
import { useStore } from 'vuex';
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';

const store = useStore();
const route = useRoute();

onMounted(async () => {
  await store.dispatch('auth/fetchUser')
})

</script>