import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/main.css'

import { createApp } from 'vue'
import App from './App.vue'

// -------------------------------

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';

awsExports.oauth.redirectSignIn = process.env.VUE_APP_AWS_REDIRECT_SIGNIN
awsExports.oauth.redirectSignOut = process.env.VUE_APP_AWS_REDIRECT_LOGOUT

Amplify.configure(awsExports);

import AmplifyVue from '@aws-amplify/ui-vue';

// -------------------------------

// Router
import router from 'router'
// Store
import store from 'store'

import './plugins/fontawesome'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const app = createApp(App).component('font-awesome-icon', FontAwesomeIcon);

app.use(AmplifyVue);
app.use(router);
app.use(store);
app.mount('#app');