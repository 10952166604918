<template>
    <div class="blank-layout">
        <router-view></router-view>
    </div>
</template>

<style scoped>
    .blank-layout {
        height: 100vh;
    }
</style>