import Blank from '../layouts/Blank.vue';
import TopNav from '../layouts/TopNav.vue';

const routes = [
  {
    path: "/",
    name: "Home",
    alias: '/home',
    component: () => import(/* webpackChunkName: "home" */ './../views/Home.vue'),
    meta: {
      layout: TopNav
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ './../views/Login.vue'),
    meta: {
      layout: Blank
    }
  },
  {
    path: '/manage-auth',
    name: 'ManageAuth',
    component: () => import(/* webpackChunckName: "manageAuth" */ './../views/ManageAuth.vue'),
    meta: {
      layout: Blank
    }
  },
  {
    path: '/check-user',
    name: 'CheckUser',
    component: () => import(/* webpackChunckName: "manageAuth" */ './../views/CheckUser.vue'),
    meta: {
      layout: Blank
    }
  }
];

export default routes;