import { createStore } from 'vuex'

// Modules
import modules from './modules'

// Create a new store instance.
const store = createStore({
  state () {
    return {
      showTools: false,
      lang: 'it'
    }
  },
  mutations: {
    setShowTools(state, payload) {
      state.showTools = payload
    }
  },
  actions: {
    toogleShowTools({commit, state}) {
      const toolState = !state.showTools
      commit('setShowTools', toolState)
    }
  },
  getters: {
    showTools(state) {
      return state.showTools
    }
  },
  modules
})

export default store;