<template>
  <nav class="navbar navbar-expand-lg bg-trasparent">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div
        class="collapse navbar-collapse d-flex justify-content-between"
        id="navbarNav"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="{name: 'Home'}"
            >
              <font-awesome-icon icon="home"></font-awesome-icon>
            </router-link>
          </li>
          <li
            v-if="user"
            class="nav-item"
          >
            <span class="nav-link pointer" @click="store.dispatch('auth/logout')">
              Logout
            </span>
          </li>
        </ul>

        <ul class="navbar-nav right-nav w-25">
          <li
            v-if="user"
            class="nav-item"
          >
            <span class="nav-link text-dark">
              Benvenuto {{user.attributes.name}}
            </span>
          </li>

          <li
            class="nav-item btn-toolbox-wrap"
            title="Strumenti utili"
          >
            <button
              class="btn bg-transparent"
              @click="store.dispatch('toogleShowTools')"
            >
              <font-awesome-icon icon="toolbox" class="toolbox--icon"/>
            </button>
          </li>

          <li
            class="nav-item d-none"
            title="Notifiche"
          >
            <button
              class="btn bg-transparent"
            >
              <font-awesome-icon icon="bell" class="notifications--icon"/>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const user = computed(() => {
  return store.getters["auth/getUser"]
});
</script>

<style scoped>

.notifications--icon {
  color: var(--bs-yellow);
}

.toolbox--icon {
  color: var(--bs-blue);
}

.right-nav {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}
</style>